
import 'swiper/css/swiper.min.css'
import Swiper from 'swiper'
export default {
  name: 'IconsSlice',
  props: ['slice'],
  data() {
    return {
      swiper: false,
      id: this.slice.id
    }
  },
  computed: {
    slides() {
      return this.slice.items || []
    }
  },
  mounted() {
    !this.slice.primary.s_class?.includes('grid-mode') ? this.initSwiper() : ''
  },
  methods: {
    initSwiper() {
      const slidesCount = this.slides.length
      const slidesPerView = this.slice.primary.s_class?.includes('features--5') ? 5 : this.slice.primary.s_class?.includes('features--3') ? 3 : 4
      this.swiper = new Swiper(`#${this.id}`, {
        observer: true,
        observeSlideChildren: true,
        observeParents: true,
        slidesPerView: 1,
        slidesPerColumn: 1,
        spaceBetween: 20,
        speed: 500,
        navigation: {
          nextEl: `#${this.id}-navigation-next`,
          prevEl: `#${this.id}-navigation-prev`
        },
        breakpoints: {
          1280: {
            slidesPerView: slidesPerView,
            allowSlideNext: false,
            allowSlidePrev: false,
            allowTouchMove: false,
            slidesPerColumn: slidesCount > 4 ? 2 : null,  
            slidesPerColumnFill: slidesCount > 4 ? 'row' : null,
            spaceBetween: 30
          },
          1024: {
            slidesPerView: 3,
            allowSlideNext: true,
            allowSlidePrev: true,
            allowTouchMove: true,
            slidesPerColumn: slidesCount > 6 ? 3 : null,  
            slidesPerColumnFill: slidesCount > 6 ? 'row' : null,
            spaceBetween: 30
          },
          768: {
            slidesPerView: 2,
          },
          480: {
            slidesPerView: 1,
          },
        },
      })
    }
  }
}
